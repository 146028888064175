






import RegisterForm from '../components/RegisterForm.vue';
import { defineComponent } from '@/plugins/composition';
import { useRouter } from '@/router';

export default defineComponent({
  components: { RegisterForm },
  name: 'RegisterPage',
  setup(props) {
    const { router, route } = useRouter();

    return {
      //
    };
  },
});
