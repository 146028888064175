































import { Client, User } from '@/models';
import { axios } from '@/plugins/axios';
import { defineComponent, onMounted, reactive, ref } from 'vue-demi';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { required, rules } from '@/domains/practitioner/modules/assessments/composables/validations';
import { routeNames } from '@/router/routeNames';
import { snakeKeys } from '@/utils';
import { storage } from '@/main';
import { useFormValidation } from '@/composables/useFormValidation';
import { useLoader } from '@/composables/useLoader';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'RegisterForm',
  setup() {
    const valid = ref(false);
    const client = ref<Client | null>(null);
    const { router, route } = useRouter();

    const showPassword = ref(false);
    const formData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      client: '',
      site: '',
    });

    onMounted(() => {
      formData.client = route.value.query.client as string;
      formData.site = route.value.query.site as string;
    });

    const { loading, withLoader } = useLoader();
    const isPasswordVisible = ref(false);

    const { errors, mapErrors } = useFormValidation(formData);

    const register = async () => {
      await axios.get('/sanctum/csrf-cookie');
      const response = await axios.post('/patients/register', snakeKeys(formData)).catch((error) => mapErrors(error));
      if (response) {
        const loggedInUser = await User.login({ email: formData.email, password: formData.password }).catch((e) => {
          console.log(e);
        });

        if (loggedInUser) {
          storage.auth.login(loggedInUser);
          router.push({ name: routeNames.patient.resources });
        }
      }
    };

    return {
      valid,
      loading,
      showPassword,

      client,
      rules,
      required,
      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      isPasswordVisible,
      formData,
      errors,
      register,
    };
  },
});
